<template>
    <div class="modal active" v-if="modelValue">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Smart Block <span>Add Smart Block</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="smart_blocklist">
                    <ul v-if="workoutSmartBlocks.length">
                        <li v-for="(block, b) in workoutSmartBlocks" :key="b">
                            <h3 class="sub_header">
                                {{ block.name }}
                                <button type="button" class="add_btn pointer ml-auto" @click="handleAddSmartBlock(block.id)"><i class="fas fa-plus"></i>Add</button>
                                <button type="button" class="delete_btn pointer" @click="handleDeleteSmartBlock(block.id)"><i class="fas fa-trash-alt danger"></i></button>
                            </h3>
                            <ul class="block_info"  v-if="block.exercises && block.exercises.length">
                                <li v-for="(exercise, e) in block.exercises" :key="e">
                                    <h6>{{ exercise.name }}</h6>
                                    <div class="video_wpr exercise">
                                        <div class="video-overlay" @click="showVideo = true; selectedExercise = exercise"></div>
                                        <!-- <div v-if="exercise.video_type == 1" class="player_wpr" v-html="parseEmbedCode(exercise.video_link)"></div>
                                        <div v-else-if="exercise.video_type == 2" class="player_wpr" v-html="exercise.embed_code"></div> -->
                                        <img :src="exercise.thumb" :alt="exercise.name">
                                    </div>
                                </li>
                            </ul>
                            <ul class="block_info" v-else>
                                <h1 class="empty-exercise">You don't have any Exercise in this block.</h1>
                            </ul>
                        </li>
                    </ul>
                    <div class="no_message" v-else>
                        You don't have any Smart block.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal secondary video_player" v-if="showVideo">
        <div class="modal_container">
            <div class="modal_header">
                <button class="close_btn" @click="showVideo = false"><i class="fas fa-times"></i></button>
            </div>
            <div class="video_wpr">
                <div v-if="selectedExercise.has_gif == 1 && selectedExercise.gif_image" class="player_wpr">
                    <img :src="selectedExercise.gif_image" :alt="selectedExercise.name">
                </div>
                <div v-else-if="selectedExercise.video_type == 1" class="player_wpr" v-html="parseEmbedCode(selectedExercise.video_link)"></div>
                <div class="player_wpr" v-else-if="selectedExercise.video_type == 2" v-html="selectedExercise.embed_code"></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import VideoParser from '@/utils/VideoParser'

    export default {
        name: 'Add Smart Block',

        data () {
            return {
                showVideo: false,
                selectedExercise: {},
            }
        },

        props: {
            modelValue: Boolean,
            workoutId: Number,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    document.body.classList.add('modal-open');

                    if (!vm.workoutSmartBlocks || !vm.workoutSmartBlocks.length) {
                        vm.getWorkoutSmartBlocks();
                    }
                } else {
                    document.body.classList.remove('modal-open');
                }
            }
        },

        computed: mapState({
            workoutSmartBlocks: state => state.workoutModule.workoutSmartBlocks,
            loader: state => state.workoutModule.workoutSmartBlockLoader,
        }),


        methods: {
            ...mapActions({
                getWorkoutSmartBlocks: 'workoutModule/getWorkoutSmartBlocks',
                createNewBlock: 'workoutModule/createNewBlock',
                getWorkoutBlocks: 'workoutModule/getWorkoutBlocks',
                deleteSmartBlock: 'workoutModule/deleteSmartBlock',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleAddSmartBlock (id) {
                const vm = this;

                const params = { workout_id: vm.workoutId, smart_block_id: id }

                vm.createNewBlock(params).then((result) => {
                    if (result) {
                        vm.getWorkoutSmartBlocks();
                        vm.getWorkoutBlocks(vm.workoutId);
                        vm.closeModal();
                    }
                });
            },

            handleDeleteSmartBlock (id) {
                const vm = this;

                const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover this workout smart block`, 'Delete');

                options.preConfirm = function () {
                                        return vm.deleteSmartBlock(id).then((result) => {
                                            if (result) {
                                                vm.getWorkoutSmartBlocks();
                                            }
                                        });
                                    };

                Swal.fire(options);
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url);
            },
        }
    }
</script>

<style scoped>
    .workout_config .modal_container {
        padding: 0;
        max-width: 700px;
        width: 100%;
    }

    .workout_config .modal_header {
        padding: 20px 30px;
        width: 100%;
        border-bottom: 1px solid #e9e9e9;
    }

     .smart_blocklist .add_btn {
        font-size: 13px;
        line-height: 16px;
    }

    .smart_blocklist .add_btn i {
        margin-right: 5px;
        font-size: 9px !important;
    }

    .smart_blocklist>ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .smart_blocklist>ul .block_info {
        border: 1px solid #f5f5f5;
        background: #fff;
        border-radius: 6px;
        display: flex;
        flex-wrap: wrap;
        padding: 7px 15px 20px 15px;
    }

    .smart_blocklist>ul .block_info>li {
        padding: 7px;
        width: 33.333%;
        display: flex;
        flex-direction: column;
        gap: 3px;
    }

    .smart_blocklist>ul .block_info li h6 {
        font-size: 9px;
        line-height: 11px;
        color: #121525;
        font-weight: 400;
        margin: 5px 0;
    }

    .video-overlay {
        position: absolute;
        z-index: 1;
        height: 100%;
        width: 100%;
        top: 0;
        cursor: pointer;
    }

    .video_wpr {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        margin: 0;
        border-radius: 5px;
        overflow: hidden;
    }

    .video_wpr iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 3px;
    }

    .block_info .empty-exercise {
        font-size: 15px;
        font-weight: 300;
        margin: 10px;
    }
    .no_message{
        padding: 30px 25px 30px 0;
        justify-content: center;
        align-items: center;
        font-family: 'Architects Daughter', cursive;
        font-size: 20px;
        line-height: 30px;
        font-weight: 300;
        color: #a5a5a5;
        text-align: center;
    }
</style>